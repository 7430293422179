globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"1964566830f94b2f81fa450b9e03456c4037dece"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.ACTIVE_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate:
    process.env.ACTIVE_ENV === 'production' ? 0.05 : 1.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.1,
  integrations: [
    Sentry.httpClientIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      // Mask all text content with asterisks (*). Passes text
      // content through to `maskTextFn` before sending to server.
      //
      // Defaults to true, uncomment to change
      maskAllText: false,

      // Block all media elements (img, svg, video, object,
      // picture, embed, map, audio)
      //
      // Defaults to true, uncomment to change
      // blockAllMedia: true,
      maskAllInputs: true,
    }),
  ],
  // https://docs.sentry.io/platforms/javascript/configuration/filtering
  networkAllowUrls: [
    // Allow all URLs that contain 'localhost' or 'vercel.app'
    /localhost/,
    /vercel\.app/,
    /resist\.bot/,
    /ginrickey\.vercel\.app/,
    // Regex that matches the entire line for vercel.app and all its subdomains
    /^[^/]*\.vercel\.app\/?$/,
  ],
  ignoreErrors: [
    'Aborted is not defined', // Comes from the Yeti crawler
    "undefined is not an object (evaluating 'window.TelegramGameProxy.receiveEvent')",
  ],
  // https://docs.sentry.io/platforms/javascript/guides/nextjs/performance/instrumentation/automatic-instrumentation/
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^\//],
});
