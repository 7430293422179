// Taken from (for Sentry error support): https://github.com/vercel/next.js/blob/d2caaeab6491f1be80b3779b83e2902fc73223f9/examples/with-sentry/pages/_app.js
import * as Sentry from '@sentry/nextjs';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { SWRConfig } from 'swr';
import '@stripe/stripe-js'; // This is needed to run the stripe fraud tracking on every page
import { load, trackPageview } from 'fathom-client';

import fetchJson from 'lib/fetchJson';
import useUser from 'lib/useUser';

// https://nextjs.org/docs/basic-features/built-in-css-support#adding-a-global-stylesheet
import '../styles/globals.css';
import './layout.scss';

const FATHOM_SITE_ID =
  process.env.FATHOM_SITE_ID || process.env.NEXT_PUBLIC_FATHOM_SITE_ID;

// Record a pageview when route changes
Router.events.on('routeChangeComplete', (as, routeProps) => {
  if (!routeProps.shallow) {
    trackPageview();
  }
});

const App = ({ Component, pageProps }) => {
  const { user } = useUser();

  // Initialize Fathom when the app loads
  useEffect(() => {
    if (process.env.IS_PROD) {
      load(FATHOM_SITE_ID, {
        includedDomains: ['resist.bot'],
      });
    }
  }, []);

  useEffect(() => {
    if (user?.id) {
      Sentry.setUser({ id: user.id });
    } else {
      Sentry.setUser({ id: null });
    }
  }, [user]);

  return (
    <SWRConfig
      value={{
        // https://swr.vercel.app/docs/api
        // This is a generic setup, that does not refresh and does basic json fetching and error handling
        // We can override this in specific components if we need different behavior
        fetcher: fetchJson,
        onError: (err) => {
          // https://swr.vercel.app/docs/error-handling#global-error-report
          Sentry.captureException(err, (scope) => {
            scope.setTransactionName(
              `SWR Global Fetcher Error: ${err.message}`
            );
            return scope;
          });
        },
      }}
    >
      <Component {...pageProps} />
    </SWRConfig>
  );
};

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
  err: PropTypes.object,
};

export default App;
